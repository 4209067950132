.App {
    font-family: sans-serif;
    text-align: center;
  }
  
.centro{
  margin: 0 auto;
}
.containerDDP{
  width: 90%;
  margin: 0 auto;
}

.drangAndDrop{
  border: dashed red;
  width: 80%;
  margin: 0 auto;
}

.drangAndDrop{
  cursor:pointer;
}

.drangAndDrop2{
  border: dashed red;
  width: 100%;
  margin: 0 auto;
  cursor:pointer;

}

.drangAndDropInput{
  width: 500px;
  text-align: center;
  height: 50px;
}



.conenediorAviso2{
  width: 520px !important;
  text-align: justify;
  margin: 0 auto; 
}


.ModalRelacionAdd{
  max-width: 70% !important;
}

.inputDataEdit{
  width: 100%;
}






.TableConsultaAddenda thead tr th:nth-child(3) {
  width: 500px !important;
}


.deletedoc{
  width: 30px;
}