.TableConsultarTodo {
  margin: 0 auto;
  width: 98%;
  font-size: 14px;
  white-space: normal !important;
}

/* CUERPO TABLA */
.TableConsultarTodo td:nth-child(12),
.TableConsultarTodo td:nth-child(13),
.TableConsultarTodo td:nth-child(14) {
  text-align: center;
}

.TableConsultarTodo td:nth-child(11) {
  text-align: right;
}

.TableConsultarTodo td:nth-child(6),
.TableConsultarTodo td:nth-child(7) {
  text-align: justify;
}

.cancel {
  cursor: pointer;
}

/* RESPONSE TABLA */
@media only screen and (max-width: 1920px) {
  .TableConsultarTodo {
    width: 98%;
    font-size: 12.8px;
  }

  #custom-select,
  #custom-filter {
    font-size: 12.8px !important;
  }
}

@media only screen and (max-width: 1680px) {
  .TableConsultarTodo {
    font-size: 10.6px;
    width: 98%;
  }
  #custom-select,
  #custom-filter {
    font-size: 10.6px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .TableConsultarTodo {
    font-size: 10px;
    width: 98%;
  }
  #custom-select,
  #custom-filter {
    font-size: 10 !important;
  }
}

@media only screen and (max-width: 1440px) {
  .TableConsultarTodo {
    font-size: 8.6px;
    width: 98.5%;
  }

  #custom-select,
  #custom-filter {
    font-size: 8.5px;
  }
  .button {
    padding: 2px ;
    width: 80px ;
    margin: 4px ;
  }
  #custom-select {
    width: 60px;
  }
}

@media only screen and (max-width: 1368px) {
  .TableConsultarTodo {
    font-size: 12px !important;
    width: 98.5%;
  }
  #custom-select,
  #custom-filter {
    font-size: 12px !important;
  }
  #custom-select {
    width: 60px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .TableConsultarTodo {
    font-size: 11px !important;
    width: 98%;
  }

  #custom-select,
  #custom-filter {
    font-size: 11!important;
  }
  #custom-select {
    width: 62px;
  }



  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

@media only screen and (max-width: 1152px) {
  .TableConsultarTodo {
    font-size: 11px;
    width: 94%;
    margin: 0 0;
    padding-left: 10px;
  }

  #custom-select,
  #custom-filter {
    font-size: 11 !important;
  }
  #custom-select {
    width: 58px;
  }


  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

@media only screen and (max-width: 1024px) {
  .TableConsultarTodo {
    font-size: 7px;
    width: 83%;
    margin: 0 0;
    padding-left: 10px;
  }

  #custom-select,
  #custom-filter {
    font-size: 7 !important;
  }
  #custom-select {
    width: 58px;
  }



  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

@media only screen and (max-width: 830px) {
  .TableConsultarTodo {
    font-size: 7px;
    max-width: 66%;
    margin: 0 0;
    padding-left: 10px;
  }

  #custom-select,
  #custom-filter {
    font-size: 7 !important;
  }
  #custom-select {
    width: 58px;
  }



  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

.div1c .fi03 {
  width: 100px;
}

.fi03 {
  width: 100px !important; 
}


.dimencionboton {
  width: 10%;
}

.filtrosBusqueda {
  display: none;
}

.div1c {
  float: left;
  margin-top: 36px;
  padding-top: 30px;
}
.div2c {
  float: left;
  margin-top: 34px;
  padding-top: 30px;
}
.div3c {
  float: left;
  margin-top: 36px;
  padding-top: 30px;
}
.div4c {
  float: left;
  margin-top: 36px;
  padding-top: 30px;
  padding-right: 40px;
}
.div5c {
  float: left;
  margin-top: 34px;
  padding-top: 30px;
}

.filtroRFC {
  width: 70%;
}

.filtroBP {
  width: 120%;
}

.expCSV {
  cursor: pointer;
}


.centermodaldescarga{
  text-align: center;
  padding-top: 250px;
}
  
.TableConsultarTodo2 thead tr div{
  color: #000000 !important;
  font-weight: bold;
  text-align: center;
}

.TableConsultarTodo2 td:nth-child(1),
.TableConsultarTodo2 td:nth-child(2){
  text-align: center;
  color: #000000 !important;
  font-weight: bold;
}
.TableConsultarTodo2 td:nth-child(3){
  color: #000000 !important;
  font-weight: bold;
  
  text-align: justify;
}

.TableConsultarTodo2 td:nth-child(4),
.TableConsultarTodo2 td:nth-child(5),
.TableConsultarTodo2 td:nth-child(6){
  color: #000000 !important;
  font-weight: bold;
  text-align: right;
}


.TableConsultarPPD td:nth-child(5),
.TableConsultarPPD td:nth-child(9),
.TableConsultarPPD td:nth-child(11){
text-align: center;
}

.TableConsultarPPD td:nth-child(8){
text-align: left;
}

.TableConsultarPPD td:nth-child(6),
.TableConsultarPPD td:nth-child(12){
text-align: right;
}

.TableConsultarTodo2{
  width: 70%;
}


.imgTam{
  width: 25px !important;
  cursor: pointer !important;
}





.contenerControllerCFDI {
  width: 80%;
}
.bp-1f {
  grid-area: cp1;
  padding-top: 15px;
}
.bp-2f {
  grid-area: cp2;
  padding-top: 15px;
}
.bp-3f {
  grid-area: cp3;
  padding-top: 10px;
}
.bp-4f {
  grid-area: cp4;
  padding-top: 15px;
}
.bp-5f {
  grid-area: cp5;
  padding-top: 15px;
}
.bp-6f {
  grid-area: cp6;
  padding-top: 10px;
}
.bp-7f {
  grid-area: cp7;
}


.contenerControllerCFDI {
  padding-top: 20px;
  position: absolute;
  display: grid;
  grid-template-areas: "cp1 cp2 cp3 cp4 cp5 cp6 cp7";
  gap: 10px;
  z-index: 10;
  cursor: pointer;
}

input{
  width: 100% !important;
}



.contanerActionImg {
  width: 90%;
  margin: 0 auto !important;
}
.fat-1 {
  grid-area: cp1;
  width: 100%;
}
.fat-2 {
  grid-area: cp2;
}
.fat-3 {
  grid-area: cp3;
}
.fat-4 {
  grid-area: cp4;
}
.fat-5 {
  grid-area: cp5;
}
.fat-6 {
  grid-area: cp6;
}
.fat-7 {
  grid-area: cp7;
}


.contanerActionImg {
  display: grid;
  grid-template-areas: "cp1 cp2"
                       "cp3 cp4"
                       "cp5 cp6";
  gap: 10px;
  z-index: 20;
  cursor: pointer;
}

.cancelIMG{
  width: 20px !important;
  max-width:none !important;
  cursor: pointer;
}

.cancelIMG2{
  width: 20px !important;
  max-width:none !important;
  cursor: pointer;
}




.contenerMCancel{
  margin: 0 auto !important;
  max-width: 90% !important;
  
}


.TableConsultarTodo thead th:nth-child(1),
.TableConsultarTodo td:nth-child(1),
.TableConsultarTodo thead th:nth-child(2),
.TableConsultarTodo td:nth-child(2),
.TableConsultarTodo thead th:nth-child(3),
.TableConsultarTodo td:nth-child(3),
.TableConsultarTodo thead th:nth-child(4),
.TableConsultarTodo td:nth-child(4),
.TableConsultarTodo thead th:nth-child(5),
.TableConsultarTodo td:nth-child(5),
.TableConsultarTodo thead th:nth-child(12),
.TableConsultarTodo td:nth-child(12),
.TableConsultarTodo thead th:nth-child(13),
.TableConsultarTodo td:nth-child(13),
.TableConsultarTodo thead th:nth-child(14),
.TableConsultarTodo td:nth-child(14){
  max-width: 100px !important;
  white-space: normal;
}

.TableConsultarTodo thead th:nth-child(7),
.TableConsultarTodo td:nth-child(7){
  max-width: 120px !important;
  white-space: normal;
}

.TableConsultarTodo thead th:nth-child(6),
.TableConsultarTodo td:nth-child(6),
.TableConsultarTodo thead th:nth-child(15),
.TableConsultarTodo td:nth-child(15){
  max-width: 250px !important;
  white-space: normal;
}



.containerCosultCancel{
  width: 70%;
}

.bp-bus1{
  grid-area: npbus1;
  padding-top: 10px;
}
.bp-bus2{
  grid-area: npbus2;
  padding-top: 10px;

}
.bp-bus3{
  grid-area: npbus3;
}


.containerCosultCancel {
  padding-top: 20px;
  position:absolute;
  display: grid;
  grid-template-areas: "npbus1 npbus1 npbus2 npbus2 npbus3 npbus3";
  gap: 10px;
}