
.modalpuntosventa{
  /* width: 90%; */
  max-width: 70% !important;
  text-align: center;
  padding: 20px;
}

/* TABLA DE USUARIOS MODULO CONFIGURACION */
.TableUsers .sticky-table-table{
    width: 100%;
  }
  .TableUsers{
    margin: 0 auto;
    width: 95% !important;
  }
  .TableUsers .titletable{
    margin-top: -30px;
    margin-bottom: -50px;
  }
  .TableUsers td:nth-child(1){
    text-align: center;
    min-width: 100px;
  }
  .TableUsers td:nth-child(2),
  .TableUsers td:nth-child(3){
    text-align: center;
  }
  .TableUsers td:nth-child(4),
  .TableUsers td:nth-child(5),
  .TableUsers td:nth-child(6){
    text-align: left;
  }
  .TableUsers td:nth-child(7),
  .TableUsers td:nth-child(8),
  .TableUsers td:nth-child(9){
    text-align: left;
  }
  .TableUsers td:nth-child(10){
   text-align: left;
  }

.adduser input{
    border:1px solid rgb(59, 59, 59);
    font-size: 18px;
    height: 50px;
}
.adduser label{
    background-color: white;
    font-size: 20px;
    color: black;
}
.pestañasusuarios{
  width: 100%;
  padding-bottom:2px ;
}
.pestañauser1{
    padding-top: 5px;
    width: 50%;
    height: 30px;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    border-left:1px solid black;
    border-right:1px solid black;
    border-top:1px solid black;
}
.pestañauser2{
    padding-top: 5px;
    width: 50%;
    height: 30px;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    border-left:1px solid black;
    border-right:1px solid black;
    border-top:1px solid black;
}
.pestañauser1, .pestañauser2{
 display: inline-block;
}
.pestañauser1:hover{
    cursor: pointer;
}
.pestañauser2:hover{
    cursor: pointer;
}

.infouser{
  padding-top: 20px;
  border-radius: 10px;
}
.infouserroles{
    padding-top: 10px;
}

.griduser1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
    "a a b b";
    grid-gap: 10px;
}
.user1{grid-area: a;}
.user2{grid-area: b;}
.griduser2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
    "a a b b";
    grid-gap: 10px;
}
.user3{grid-area: a;}
.user4{grid-area: b;}
.griduser3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 20% 20% 25%;
    grid-template-areas:
    "a a a a";
    grid-gap: 10px;
}
.user5{grid-area: a;}


.drag-and-drop-user{
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.drag-and-drop-user h2{
    font-size: 20px;
}

.lista-izquierda-drag,
.lista-derecha-drag {
  flex: 1;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}
.lista-derecha-drag{
    background-color:#E1E1E1;
}


.item-drag, .item-drop {
  display: flex;
  align-items: center; 
  justify-content: left;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  color: #333;
}
.item-drop img{
 padding-right: 10px;
}
.itemnamerole{
    text-align: left;
    width: 90%;
}
.itemnamerole{
    background-color: transparent;
}

.item-drag img{
    width: 30px;
    height: 30px;
}
.item-drop img{
  width: 35px;
  height: 25px;
}

.item-drop img:hover{
    cursor: pointer;
}
.item-drag img:hover {
  cursor: pointer;
}

.item-drag:hover, .item-drag:hover{
    background-color: rgb(235, 234, 234);
}
.veriphone{
  text-align: center;
  font-size: 13px;
  padding-left: 5%;
  margin-top: -10px;
}
.vericorreo{
  text-align: center;
  font-size: 14px;
  color: red;
  font-weight: bold;
  margin-top: -10px;
}







@media only screen and (max-width: 720px) {
  .griduser1{
    grid-template-areas:
    "a a a a"
    "b b b b";
   }
   .griduser2{
    grid-template-areas:
    "a a a a"
    "b b b b";
   }
} 

