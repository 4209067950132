
/* Tabla de roles  */
.TableRoles{
    margin: 0 auto;
    width: 90% !important;
  }
  .TableRoles .sticky-table-table{
    width: 100%;
  }
  .TableRoles .titletable{
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .TableRoles td:nth-child(1){
    text-align: center;
    width: 10%;
  }
  .TableRoles td:nth-child(1) img{
    width: 30px;
    height: 30px;
  }
  
  
  .TableRoles td:nth-child(2),
  .TableRoles td:nth-child(3){
    text-align: left;
  }

  .addpointsalecss input{
    height: 50px;
    font-size: 18px;
    border: 2px solid rgba(0, 0, 0, 0.203);
  }
  .addpointsalecss label{
    color: black;
    font-size: 18px;
    background-color: white;
  }

  .addpointsalecss select{
    height: 20px;
    font-size: 18px;
  }
  .addpointsalecss .inputpos{
    height: 70px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
  }
  
  