.grid-sum{
    margin: 0 auto;
    width: 95%;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-template-areas: "a b";
    grid-gap: 100px;
}
.table-min-m{
    grid-area: a;
    width: 90%;
    margin: 0 auto;
}
.table-min-m .sticky-table-table{
    width: 100%;
}
.table-min-m td:nth-child(1){
    text-align: center;
}
.table-min-m td:nth-child(1) img{
    width: 25px;
    height: 25px;
}
.color-cancel{
    background-color: red;
}



.table-min-fcst{
    grid-area: b;
    width: 100%;
    margin: 0 auto;
}
.table-min-fcst .sticky-table-table{
    width: 100%;
}
.table-min-fcst td:nth-child(1){
    text-align: center;
}

.table-min-fcst td:nth-child(1) img{
    width: 25px;
    height: 25px;
}
.table-min-fcst th:nth-child(1) img{
    width: 25px;
    height: 25px;
}

.table-min-fcst th:nth-child(1),
.table-min-fcst th:nth-child(2),
.table-min-fcst th:nth-child(3),
.table-min-fcst th:nth-child(4){
    text-align: center;
}


.table2 {
    text-align: left;
}
.button-graph{
    width: 150px;
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    position: relative;
    z-index: 150;
}