.tablemps{
  width: 95%;
  margin: 0 auto;
  margin-top: -40px;
}
.tablemps .sticky-table-table{
  width: 100%;
}

.tablemps th:nth-child(1){
  width: 80px;
  min-width: 60px;
}
.tablemps th:nth-child(1) img{
  width: 30px;
  height: 30px;
}
.tablemps td:nth-child(1) img{
  width: 25px;
  height: 25px;
}
.tablemps td{
  width: 100px;
  max-width: 120px;
  text-align: left;
  overflow-x: auto;
  white-space: normal !important;
}

.tablemps td:nth-child(1){
  text-align: center;
}
.tablemps td:nth-child(2),
.tablemps td:nth-child(3),
.tablemps td:nth-child(4),
.tablemps td:nth-child(5),
.tablemps td:nth-child(6),
.tablemps td:nth-child(7),
.tablemps td:nth-child(8),
.tablemps td:nth-child(9),
.tablemps td:nth-child(10){
  text-align: left;
}
.tablemps td:nth-child(11),
.tablemps td:nth-child(12){
  text-align: center;
  min-width: 100px;
}
.tablemps td:nth-child(13){
  text-align: center;
}
.tablemps th:nth-child(13){
  background-color: rgb(255, 176, 28);
}
.tablemps td:nth-child(15){
  text-align: center;
}

.tablemps td:nth-child(16){
  width: 250px;
  min-width: 250px;
  text-align: justify;
}

.tablemps td:nth-child(17),
.tablemps td:nth-child(18){
  text-align: center;
}


.tablemps thead th:nth-child(19),
.tablemps thead th:nth-child(20),
.tablemps thead th:nth-child(21){
  background-color: red;
}
.tablemps td:nth-child(19),
.tablemps td:nth-child(20),
.tablemps td:nth-child(21),
.tablemps td:nth-child(22),
.tablemps td:nth-child(23),
.tablemps td:nth-child(24){
  text-align: right;
}
.tablemps td:nth-child(25),
.tablemps td:nth-child(26),
.tablemps td:nth-child(27){
  text-align: center;
}
.tablemps td:nth-child(28),
.tablemps td:nth-child(29),
.tablemps td:nth-child(30){
  text-align: right;
}

.tablemps thead th:nth-child(22),
.tablemps thead th:nth-child(23),
.tablemps thead th:nth-child(24),
.tablemps thead th:nth-child(25),
.tablemps thead th:nth-child(26),
.tablemps thead th:nth-child(27),
.tablemps thead th:nth-child(28),
.tablemps thead th:nth-child(29),
.tablemps thead th:nth-child(30){  
  background-color: rgb(5, 37, 181);
}
.modalish{
  min-width: 80% !important;
  font-size: 18px;
  text-align: center;
}
.modalish input{
  font-size: 18px;
  height: 50px;
}
.modalish label{
  font-size: 20px;
}
.modalish select{
  height: 20px;
  font-size: 18px;
}

.container_form{
  width: 95%;
  margin: 0 auto;
}

.grid-ish1, .grid-ish3{
  display: grid;
  width: 100%;
  grid-template-columns: 24% 24% 24% 24%;
  grid-template-areas: "a b c d";
  grid-gap: 10px;
}
.grid-ish2{
  display: grid;
  width: 100%;
  grid-template-columns: 24% 15% 18% 19% 19%;
  grid-template-areas: "a b c d e";
  grid-gap: 10px;
}
.grid-ish4{
  display: grid;
  width: 100%;
  grid-template-columns: 24% 15% 18% 19% 19%;
  grid-template-areas: "a b b b b";
  grid-gap: 10px;
}
.input-ish14{grid-area: a;}
.input-ish15{grid-area: b;}
.grid-ish5{
  display: grid;
  width: 100%;
  grid-template-columns: 24% 24% 24% 24%;
  grid-template-areas: "a b c d";
  grid-gap: 10px;
}