.menu-superior-graphic{
    margin: 0 auto;
    display: grid;
    width: 100%;
    grid-template-columns: 8% 8%;
    grid-template-areas:
    "a b";
    grid-gap:10px;
    margin-left: 20px;
}

.grid-button1{
    grid-area: a;
}
.grid-button2{
    grid-area: b;
}

.grid-button1 button, .grid-button2 button{
    width: 100%;
    color: white;
    font-size: 16px;
}

.filtros_personalizados{
    padding-top: 20px;
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 10%;
    grid-template-areas:
    "a b";
    grid-gap:20px;
}

.fil1{grid-area: a;}
.fil2{grid-area: b;}
.fil2 button{
    width: 90%;
    height: 52px;
    color: white;
    margin-top: 12px;
    font-size: 17px;
}