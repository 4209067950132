.App {
  font-family: sans-serif;
  text-align: center;
}
.alerts button {
  padding: 5px 10px;
  margin: 0.5rem;
  flex: 1 100px;
  max-width: 100px;
  cursor: pointer;
}
.alerts {
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-end;
}




.alert-modal-content{
    position: absolute;
    bottom: 0;}

#alert-modal-content{
    position: absolute;
    bottom: 0px; left: 0px;
}