.TableCOnsultaClientes {
  margin: 0 auto;
  width: 95%;
}

/* CUERPO TABLA */

.TableCOnsultaClientes td:nth-child(3){ 
  text-align: center;
}
.TableCOnsultaClientes td:nth-child(4){
  text-align: justify;
}

.TableCOnsultaClientes td:nth-child(1),
.TableCOnsultaClientes td:nth-child(2),
.TableCOnsultaClientes td:nth-child(5),
.TableCOnsultaClientes td:nth-child(7),
.TableCOnsultaClientes td:nth-child(8),
.TableCOnsultaClientes td:nth-child(9),
.TableCOnsultaClientes td:nth-child(10){
  text-align: center;
}

/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableCOnsultaClientes  {
      font-size: 13px;
      width: 97%;

  }
}

@media only screen and (max-width: 1320px) {
  .TableCOnsultaClientes  {
      font-size: 12px;
      width: 97%;

  }
}

@media only screen and (max-width: 1059px) {
    .TableCOnsultaClientes  {
        font-size: 9.5px;
        width: 98%;

    }
}

@media only screen and (max-width: 890px) {
    .TableCOnsultaClientes  {
        font-size: 8.5px;
    }
    .TableCOnsultaClientes{
        width: 100%;
    }
}

.cursorPointer{
  cursor: pointer;
}

.modalSetBussnes{
  width: 80% !important;
}


.switchRegister{
  padding-left: 20px;
  padding-top: 10px;
}

.Modal-module_modal__FqeFw{
  overflow:unset !important;
}

body{
  --st-overlay-perspective:none !important;
}

.idAler{
  background-color: black !important;
}


.alertNotification{
  color: white !important;
  font-size: 25px !important;
  
}

.iconctnAlert{
  margin-top: 5px;
  width: 70%;
}





  
.ctnAler { grid-area: 1 / 1 / 3 / 2; }
.ctnAler2 { grid-area: 1 / 2 / 2 / 4; }
.ctnAler3 { grid-area: 2 / 2 / 3 / 4; }


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  opacity: unset !important;
  -webkit-text-fill-color: black !important;
}



.css-1kw4qaw-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white !important;
  box-shadow: none !important;
}



.containerBussnesP{
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
  gap: 10px;
  position: relative;
  width: 90%;
  z-index: 1;
  padding-left: 2.5%;
  margin: 10px;
}




.contenirdivH{
  width: 100%;
  margin-left: 3%;
}

/* .modalTItle{
  height: auto;
  overflow: scroll;
} */
.modalpuntosventa{
  max-height: 100vh;
  position: absolute;
  margin-top: 1%;
}

.p-actions{
  margin-bottom: 0px !important;
  font-weight: bold !important;
  color: black !important;
}


.icon_add_qr{
  cursor: pointer;
  width: 30px !important;
}

.miIconButton{
  margin-bottom: 5px;
  width: 30px;
  position: absolute;
}

.new_table_v2 table tbody td:nth-child(5){
  text-align: center !important;
}

