.caja {
    margin:20px auto 40px auto;	
    border:1px solid #d9d9d9;
    height:30px;
    overflow: hidden;
    width: 80%;
    position:relative;
    text-align: center;
 }
 select {
    background: transparent;
    border: none;
    font-size: 14px;
    height: 30px;
    padding: 5px;
    width: 100%;
 }
 select:focus{ outline: none;}
 
 .caja::after{
     content:"\025be";
     display:table-cell;
     padding-top:7px;
     text-align:center;
     width:30px;
     height:30px;
     background-color:#d9d9d9;
     position:absolute;
     top:0;
     right:0px;	
     pointer-events: none;
 }

.modalTItle{
  width: 80% !important;
  max-width: 80% !important;
    padding-top: 30px;
    text-align: center;
    padding-bottom: 20px;
}



/*CLase que aplica los estilos al elemento a aparecer*/
.show-element {
    /*duracion de la animacion, puedes variar el tiempo, entre mas grande mas tarda el efecto*/
    animation-duration: 2s;
    animation-fill-mode: both;
    /*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
    animation-name: fadeIn;
    background-color: rgb(225, 225, 255);
  }
  
  .hide-element {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: fadeOut;
  }
  
  /*KeyFrames*/
  /*La animacion hecha por ti, puedes ponerle cualquier nombre, solo es para identificarlo*/
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /*Puedes hacer mas animaciones personalizadas con todo lo que pueda hacer css*/
  @keyframes otherAnimation {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes animationTranslateIn {
    from {
      opacity: 0;
      transform: translateX(-200px);
    }
  
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  
  @keyframes animationTranslateOut {
    from {
      opacity: 1;
      transform: translateX(0px);
    }
  
    to {
      opacity: 1;
      transform: translateX(-200px);
    }
  }
  
  .div1cM01{
    float: left;
  }
  .div2cM01{
    margin-top: 50px;
    float: left;
    /* width: 20%; */
  }
  
  .div2cM012{
    margin-top: 50px;
    float: left;
     width: 20%; 
  }


  .selectMR{
    width: 97%;
  }