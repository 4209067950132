.upload-file-xlsx{
    text-align: center;
    width: 600px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

}

.upload-file-modal{
    font-size: 20px;
}

.upload-area{
    height: 100px;
}
.table-ihs td:nth-child(1){
    min-width: 50px !important;
    width: 50px;
}