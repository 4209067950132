.TableCOnsultaClientes {
  margin: 0 auto;
  width: 95%;
}

.table-ihs{
  margin: 0 auto;
  width: 95%;
  overflow-y: auto;
}
.TableConsulDemo{
  margin: 0 auto;
  width: 98%;
  overflow-x: auto;
  white-space: normal !important;
  font-size: 15px !important;
}

.TableConsulDemo table tr td{
  white-space: normal !important;

}

.TableConsulDemo td:nth-child(1),
.TableConsulDemo td:nth-child(5),
.TableConsulDemo td:nth-child(6),
.TableConsulDemo td:nth-child(7),
.TableConsulDemo td:nth-child(10),
.TableConsulDemo td:nth-child(12),
.TableConsulDemo td:nth-child(13),
.TableConsulDemo td:nth-child(14),
.TableConsulDemo td:nth-child(15),
.TableConsulDemo td:nth-child(16){
  text-align: center;
}

.TableConsulDemo td:nth-child(8),
.TableConsulDemo td:nth-child(9){ 
  text-align: justify;
}
.TableReport thead th:nth-child(13),
.TableConsulDemo td:nth-child(6){ 
  max-width: 150px !important;
}

.anualSales thead th:nth-child(18),
.anualSales thead th:nth-child(19),
.anualSales thead th:nth-child(20),
.anualSales thead th:nth-child(21),
.anualSales thead th:nth-child(22),
.anualSales thead th:nth-child(23),
.anualSales thead th:nth-child(24),
.anualSales thead th:nth-child(25),
.anualSales thead th:nth-child(26){ 
  background-color: rgb(5, 37, 181);
}
.anualSales thead tr:nth-child(2) th:nth-child(3),
.anualSales thead tr:nth-child(2) th:nth-child(4),
.anualSales thead tr:nth-child(2) th:nth-child(5){ 
  background-color: rgb(5, 37, 181);
}

.anualSales thead tr:nth-child(1) th:nth-child(3){
  background-color: rgb(5, 37, 181);
}


.anualSales thead th:nth-child(27),
.anualSales thead th:nth-child(28),
.anualSales thead th:nth-child(29),
.anualSales thead th:nth-child(30),
.anualSales thead th:nth-child(31),
.anualSales thead th:nth-child(32),
.anualSales thead th:nth-child(33),
.anualSales thead th:nth-child(34),
.anualSales thead th:nth-child(35){ 
  background-color: red;
}
.anualSales thead tr:nth-child(2) th:nth-child(6),
.anualSales thead tr:nth-child(2) th:nth-child(7),
.anualSales thead tr:nth-child(2) th:nth-child(8){ 
  background-color: red;
}

.anualSales thead tr:nth-child(1) th:nth-child(4){
  background-color: red;
}






/* CUERPO TABLA */

.TableCOnsultaClientes td:nth-child(3){ 
  text-align: center;
}
.TableCOnsultaClientes td:nth-child(4){
  text-align: justify;
}

.TableCOnsultaClientes td:nth-child(1),
.TableCOnsultaClientes td:nth-child(2),
.TableCOnsultaClientes td:nth-child(5),
.TableCOnsultaClientes td:nth-child(7),
.TableCOnsultaClientes td:nth-child(8),
.TableCOnsultaClientes td:nth-child(9),
.TableCOnsultaClientes td:nth-child(10){
  text-align: center;
}

/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableCOnsultaClientes  {
      font-size: 13px;
      width: 97%;

  }
}


@media only screen and (max-width: 1320px) {
  .TableCOnsultaClientes  {
      font-size: 12px;
      width: 97%;

  }
}

@media only screen and (max-width: 1059px) {
    .TableCOnsultaClientes  {
        font-size: 9.5px;
        width: 98%;

    }
}

@media only screen and (max-width: 890px) {
    .TableCOnsultaClientes  {
        font-size: 8.5px;
    }
    .TableCOnsultaClientes{
        width: 100%;
    }
}

.cursorPointer{
  cursor: pointer;
}

.modalSetBussnes{
  max-width: 70% !important;
}


.switchRegister{
  padding-left: 20px;
  padding-top: 10px;
}

.Modal-module_modal__FqeFw{
  overflow:unset !important;
}

body{
  --st-overlay-perspective:none !important;
}

.idAler{
  background-color: black !important;
}
.notifications-br{
  margin: 20px !important;
  width: 320px !important;
  border-radius: 10px !important;
  border-radius: 20px !important;


}

.notifications-br .notification{
  border-radius: 20px !important;

}

.alertNotification{
  color: rgb(0, 0, 0) !important;
  font-size: 25px !important;
}

.alertNotification2{
  color: rgb(255, 255, 255) !important;
  font-size: 25px !important;
}

.colorAlerN2{
  color: rgb(255, 255, 255) !important;
}
.colorAlerN{
  color: rgb(0, 0, 0) !important;

}

.iconctnAlert{
  margin-top: 5px;
  width: 70%;
}

.alertNotificationContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgb(123, 235, 119) !important;
  border-radius: 20px !important;

  }


.alertNotificationContainerError {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgb(231, 24, 24) !important;
  border-radius: 20px !important;

  }
  
.ctnAler { grid-area: 1 / 1 / 3 / 2; }
.ctnAler2 { grid-area: 1 / 2 / 2 / 4; }
.ctnAler3 { grid-area: 2 / 2 / 3 / 4; }


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  opacity: unset !important;
  -webkit-text-fill-color: black !important;
}



.css-1kw4qaw-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white !important;
  box-shadow: none !important;
}

.containerBussnesP{
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
  gap: 10px;
  position: absolute;
  width: 90%;
  padding-top: 20px;
  z-index: 1;
  padding-left: 2.5%;
}

.modalDemo{
  max-width: 100% !important;
}

.ihsbt{
  cursor: pointer;
  width: 300px !important;
}