.containerGeneralCatalogsResponse {
    width: 60%;
    margin: 2% auto;
  }

.containerGeneralCatalogsResponse button{
  width: 100%;
  height: 100px;
}
.containerGeneralCatalogsResponse button svg{
  width: 40px;
  height: 40px;
}
  .ctl-1 {
    grid-area: cp14;
  }
  .ctl-2 {
    grid-area: cp2;
  }
  .ctl-3 {
    grid-area: cp12;
  }
  .ctl-4 {
    grid-area: cp1;
  }
  .ctl-5 {
    grid-area: cp13;
  }
  .ctl-6 {
    grid-area: cp3;
  }
  .ctl-7 {
    grid-area: cp5;
  }
  .ctl-8 {
    grid-area: cp6;
  }
  .ctl-9 {
    grid-area: cp8;
  }
  .ctl-10 {
    grid-area: cp9;
  }
  .ctl-11 {
    grid-area: cp10;
  }
  .ctl-12 {
    grid-area: cp11;
  }
  .ctl-13 {
    grid-area: cp7;
  }
  .ctl-14 {
    grid-area: cp4;
  }
  
  .containerGeneralCatalogsResponse {
    display: grid;
    grid-template-areas: "cp1  cp2  cp3  cp4 "
                        "cp5  cp6  cp7  cp8 "
                        "cp9  cp10  cp11  cp12 "
                        "cp13 cp14 cp15 cp16";
  
  }
  .table-catalogs{
    width: 90%;
    margin: 0 auto;
  }

  .table-catalogs .sticky-table-table{
    width: 100%;
 }
.table-catalogs th:nth-child(1),
.table-catalogs th:nth-child(2),
.table-catalogs th:nth-child(3),
.table-catalogs th:nth-child(4){
  text-align: center;
}
.table-catalogs td:nth-child(1){
  text-align: center;
}

.table-catalogs th:nth-child(1) img:hover{
  cursor: pointer;
}
.table-catalogs td:nth-child(1) img:hover{
  cursor: pointer;
}

.modalSetBussnes{
  text-align: center;
  font-size: 17px;
  padding-bottom: 10px;
  max-width: 50% !important;
}

.modalClosingChances{
  text-align: center;
  font-size: 17px;
  padding-bottom: 10px;
  max-width: 50% !important;
}

.modalClosingChances .table-catalogs{
  width: 90%;
  margin: 0 auto;
}
.modalClosingChances .table-catalogs td:nth-child(3){
  text-align: justify;
}
